import { lazy } from "react";

const { Routes, Route } = require("react-router-dom");

const Login = lazy(() => import("./Login"));

const RoutesPublic = () => {
  return (
    <Routes>
      <Route>
        <Route path="login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default RoutesPublic;
