import axios from "axios";

export default class Warehouse {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_STOCK;
    this.name = "/stock";
    this.url = this.base_url + this.name;
  }

  update = async (data) => {
    await axios.put(`${this.url}/v1`, data);
  };
}
