import axios from "axios";

import Person from "./Person";

class DocumentType extends Person {
  constructor() {
    super();
    this.name = "/tipo_documento";
    this.url = this.base_url + this.name;
  }

  select = async () => {
    return await axios.get(`${this.url}/select`);
  };
}

export default DocumentType;
