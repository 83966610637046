class Person {
  from_form_to_api = (data) => {
    return {
      full_name: data.full_name,
      condicion_fiscal_id: data.fiscal_condition_id,
      tipo_documento_id: data.document_type_id,
      characteristics:
        data.characteristics.length === 1 &&
        (!data.characteristics[0].characteristic_type_id ||
          !data.characteristics[0].value)
          ? []
          : data.characteristics,
    };
  };

  from_api_to_grid = (rows) => {
    return rows.map((data) => ({
      id: data.id,
      full_name: data.full_name,
      condicion_fiscal: data.condicion_fiscal?.name,
      tipo_documento: data.tipo_documento?.name,
      active: data.active,
      characteristics: data.characteristic_option.map((characteristic) => ({
        characteristic_name:
          characteristic.characteristic_value.characteristic_type.value,
        characteristic_value: characteristic.characteristic_value.value,
      })),
    }));
  };
}

export default new Person();
