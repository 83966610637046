import axios from "axios";

export default class CategoryType {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_CATALOG;
    this.name = "/category_types";
    this.url = this.base_url + this.name;
  }

  grid = async (skip = 0, take = 100, isActive) => {
    const which_one = `/${isActive ? "activos" : "inactivos"}`;

    return await axios.get(`${this.url}${which_one}?skip=${skip}&take=${take}`);
  };

  getById = async (id) => {
    return await axios.get(`${this.url}/${id}`);
  };

  select = async () => {
    return await axios.get(`${this.url}/select`);
  };

  create = async (data) => {
    await axios.post(`${this.url}`, data);
  };

  update = async (id, data) => {
    await axios.put(`${this.url}/${id}`, data);
  };

  changeActive = async (id, data) => {
    await axios.patch(`${this.url}/${id}`, data);
  };
}
