import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import { Auth } from "../../services/Authentication";
import { Firebase } from "src/services/Firebase";
import { FirebaseError } from "firebase/app";

const initialState = {
  loading: true,
  user: {},
  firebase_user: {},
  permissions: [],
  message: "",
  formIsReady: false,
  error: "",
};

const authService = new Auth();
const firebaseService = new Firebase();

const REDUCER_MODULE_NAME = "AUTH";

export const login = createAsyncThunk(
  `LOGIN_${REDUCER_MODULE_NAME}`,
  async ({ email, password }) => {
    try {
      await firebaseService.login(email, password);

      const { data } = await authService.login({ email, password });
      const { claims } = jwtDecode(data.token);

      localStorage.setItem("user", JSON.stringify({ token: data.token }));

      return {
        user: { ...claims },
        firebase_user: {},
        permissions: [...data.permissions],
        message:
          "Inicio de sesión exitoso, será redirigido al inicio del sistema.",
        error: null,
      };
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.log("ERROR EN FIREBASE <------> ", error);
        if (error.code === "auth/wrong-password") {
          return {
            user: {},
            firebase_user: {},
            permissions: [],
            message: "",
            error: "Usuario o contraseña incorrectos.",
          };
        }
      }

      return {
        user: {},
        firebase_user: {},
        permissions: [],
        message: "",
        error: error.message,
      };
    }
  }
);

export const setFirebaseUser = createAsyncThunk(
  `SET_FIREBASE_USER_${REDUCER_MODULE_NAME}`,
  async (payload) => {
    return { firebase_user: payload };
  }
);

export const authSlice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {
    clearAfterLogin(state) {
      const { user, permissions } = current(state);

      return {
        ...initialState,
        user,
        permissions,
      };
    },
    logout() {
      firebaseService.logout();
      localStorage.removeItem("user");
      localStorage.removeItem("persist:smart360");

      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [login.pending.type]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [setFirebaseUser.pending.type]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [login.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        firebase_user: action.payload.firebase_user,
        permissions: action.payload.permissions,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
    [setFirebaseUser.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        firebase_user: action.payload.firebase_user,
      };
    },
  },
});

export const { logout, clearAfterLogin } = authSlice.actions;

export default authSlice.reducer;
