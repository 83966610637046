import axios from "axios";

export default class Auth {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_AUTH;
    this.name = "/public";
    this.url = this.base_url + this.name;
  }

  login = async (payload) => {
    return await axios.post(`${this.url}/login`, payload);
  };
}
