import axios from "axios";

export default class Profile {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_USER;
    this.name = "/profiles";
    this.url = this.base_url + this.name;
  }

  grid = async (skip = 0, take = 100, active) => {
    return await axios.get(
      `${this.url}?skip=${skip}&take=${take}&active=${active}`
    );
  };

  getById = async (id) => {
    return await axios.get(`${this.url}/${id}`);
  };

  select = async () => {
    return await axios.get(`${this.url}/select`);
  };

  create = async (data) => {
    await axios.post(`${this.url}`, data);
  };

  update = async (id, data) => {
    await axios.put(`${this.url}/${id}`, data);
  };

  changeActive = async (id) => {
    await axios.patch(`${this.url}/${id}`);
  };
}
