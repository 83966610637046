export default class User {
  from_form_to_api = (data) => {
    return {
      full_name: data.full_name,
      password: data.password,
      mail: data.mail,
      profile_id: data.profile_id,
      //characteristics: (data.characteristics.length === 1 && (!data.characteristics[0].characteristic_type_id || !data.characteristics[0].value)) ? [] : data.characteristics
    };
  };

  from_api_to_grid = (rows) => {
    return rows.map((data) => ({
      id: data.id,
      full_name: data.full_name,
      mail: data.mail,
      profile: data.profile?.name,
      active: data.active,
      /*characteristics: data.characteristic_option.map((characteristic) => ({
                characteristic_name: characteristic.characteristic_value.characteristic_type.value,
                characteristic_value: characteristic.characteristic_value.value
            }))*/
    }));
  };
}
