class Catalog {
  from_form_to_api = (data) => {
    return {
      ...data,
      start_at: data.start_at
        ? data.start_at.toLocaleDateString("es-AR")
        : null,
      finish_at: data.finish_at
        ? data.finish_at.toLocaleDateString("es-AR")
        : null,
      active: true,
    };
  };

  from_api_to_form = (data) => {
    return {
      ...data,
      start_at: data.start_at ? new Date(data.start_at) : null,
      finish_at: data.finish_at ? new Date(data.finish_at) : null,
    };
  };
}

export default new Catalog();
