export const addTitleOption = (options, label = "Seleccione una opción.") => [
  { value: "", label },
  ...options,
];

export const createOptions = (
  values,
  inputKeys = ["id", "value"],
  returnKeys = ["value", "label"]
) =>
  values.map((item) => ({
    [returnKeys[0]]: item[inputKeys[0]],
    [returnKeys[1]]: item[inputKeys[1]],
  }));

export const disablePickedOptions = (
  data,
  picks,
  item_compare = "characteristic_type_id"
) =>
  data?.map((item) => ({
    ...item,
    isDisabled: picks?.some((pick) => pick[item_compare] === item.value),
  }));
