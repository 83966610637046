import axios from "axios";

class CharacteristicType {
  constructor(base_url) {
    this.name = "/characteristic_types";
    this.url = base_url + this.name;
  }

  select = async (leaf) => {
    return await axios.get(`${this.url}/select${leaf && `/leaf/${leaf}`}`);
  };
}

export default CharacteristicType;
