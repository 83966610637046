import axios from "axios";

import Person from "./Person";

class FiscalCondition extends Person {
  constructor() {
    super();
    this.name = "/condicion_fiscal";
    this.url = this.base_url + this.name;
  }

  select = async () => {
    return await axios.get(`${this.url}/select`);
  };
}

export default FiscalCondition;
