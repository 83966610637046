import React, { Component, Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import "./scss/style.scss";
import RoutesPublic from "./views/public/Routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 400:
        return Promise.reject(error.response.data);
      // TODO: revisar como manejar el fin de la sesión
      case 401:
        return Promise.reject(error.response.data);
      case 500:
        return Promise.reject(error.response.data);
    }
  }
);

const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/public/*" element={<RoutesPublic />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
