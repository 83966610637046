import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Product as ProductService } from "../../services/Catalog Management";
import { Product as ProductAdapter } from "../../adapters/Catalog Management";
import { Warehouse as WarehouseService } from "src/services/Stock Management";

import {
  select as select_characteristic_types,
  select_skus as select_skus_characteristic_types,
} from "./characteristic_type";
import { select as select_categories } from "./category";
import {
  //ready,
  select as select_characteristic_values,
} from "./characteristic_value";

const service = new ProductService();
const warehouseService = new WarehouseService();
const adapter = new ProductAdapter();

const REDUCER_MODULE_NAME = "PRODUCT";

const initialState = {
  grid: {
    activos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
    inactivos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
  },
  stock: [],
  loading: false,
  formIsReady: false,
  data: "",
  data_not_adapted: "",
  message: "",
  error: "",
};

const loadingReducer = (state, _) => {
  return {
    ...state,
    formIsReady: false,
    loading: true,
  };
};

export const check_characteristic_values_select = createAsyncThunk(
  `CHECK_CHARACTERISTIC_VALUES_SELECT_${REDUCER_MODULE_NAME}`,
  async ({ characteristics, position }, { dispatch }) => {
    dispatch(
      select_characteristic_values({
        base_url: service.base_url,
        characteristic_type: characteristics[position].characteristic_type_id,
      })
    );
  }
);

export const grid_activos = createAsyncThunk(
  `GRID_ACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, 1);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const grid_inactivos = createAsyncThunk(
  `GRID_INACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, 0);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const get_stock_v1 = createAsyncThunk(
  `STOCK_V1_${REDUCER_MODULE_NAME}`,
  async () => {
    const { data } = await service.stockV1();

    const dataLocalStorage = JSON.parse(localStorage.getItem("stockV1"));

    if (
      dataLocalStorage &&
      dataLocalStorage.length > 0 &&
      data.length === dataLocalStorage.length
    ) {
      return dataLocalStorage.map((item) => ({
        id: item.id,
        articulo: item.articulo,
        stock_total: item.stock_total,
        stock_available: item.stock_available,
        carga: isNaN(item.carga) ? 0 : parseInt(item.carga),
        descarga: isNaN(item.descarga) ? 0 : parseInt(item.descarga),
      }));
    } else {
      return data.map((item) => ({
        id: item.articulo,
        articulo: item.name,
        stock_total: item.stock_total,
        stock_available: item.stock_available,
        carga: 0,
        descarga: 0,
      }));
    }
  }
);

export const changeActive = createAsyncThunk(
  `CHANGE_ACTIVE_${REDUCER_MODULE_NAME}`,
  async ({ id, active }, { dispatch }) => {
    await service.changeActive(id, { active: !active });

    dispatch(
      grid_activos({
        skip: initialState.grid.activos.skip,
        take: initialState.grid.activos.take,
      })
    );
    dispatch(
      grid_inactivos({
        skip: initialState.grid.inactivos.skip,
        take: initialState.grid.inactivos.take,
      })
    );
  }
);

export const form = createAsyncThunk(
  `FORM_${REDUCER_MODULE_NAME}`,
  async (id, { dispatch, getState }) => {
    if (id) {
      await dispatch(getById(id));
      const { product } = getState();

      let index = 0;
      for (const variant of product.data.variants) {
        dispatch(
          check_characteristic_values_select({
            characteristics: product.data.variants,
            position: index,
          })
        );
        index++;
      }
    }

    await Promise.all([
      dispatch(select_categories()),
      dispatch(
        select_characteristic_types({
          base_url: service.base_url,
          leaf: "PRODUCT",
        })
      ),
      dispatch(
        select_skus_characteristic_types({
          base_url: service.base_url,
          leaf: "SKU",
        })
      ),
    ]);
  }
);

export const getById = createAsyncThunk(
  `GET_${REDUCER_MODULE_NAME}`,
  async (id) => {
    const entity = await service.getById(id);

    return {
      entity: entity.data,
    };
  }
);

export const submit = createAsyncThunk(
  `SUBIMIT_FORM_${REDUCER_MODULE_NAME}`,
  async ({ id, data }) => {
    try {
      const data_to_submit = adapter.from_form_to_api(data);

      if (id) {
        await service.update(id, data_to_submit);
      } else {
        await service.create(data_to_submit);
      }

      return {
        error: "",
        message: `Producto ${id ? "modificado" : "creado"} correctamente.`,
      };
    } catch (error) {
      return {
        error: error.message,
        message: "",
      };
    }
  }
);

export const submitStockV1 = createAsyncThunk(
  `SUBIMIT_STOCKV1_FORM_${REDUCER_MODULE_NAME}`,
  async ({ data }, { dispatch }) => {
    try {
      await warehouseService.update(data);

      localStorage.removeItem("stockV1");

      return {
        error: "",
        message: `Stock modificado correctamente.`,
      };
    } catch (error) {
      return {
        error: error.message,
        message: "",
      };
    }
  }
);

export const slice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {
    clean() {
      return {
        ...initialState,
      };
    },
    cleanMessage() {
      return {
        ...initialState,
        message: "",
        error: "",
      };
    },
  },
  extraReducers: {
    [grid_activos.pending.type]: loadingReducer,
    [grid_inactivos.pending.type]: loadingReducer,
    [getById.pending.type]: loadingReducer,
    [form.pending.type]: loadingReducer,
    [get_stock_v1.pending.type]: loadingReducer,
    [submit.pending.type]: loadingReducer,
    [submitStockV1.pending.type]: loadingReducer,
    //[check_characteristic_values_select.pending.type]: loadingReducer,

    [grid_activos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          activos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: adapter.from_api_to_grid(action.payload.grid_data.data),
            //data: action.payload.grid_data.data
          },
        },
      };
    },
    [grid_inactivos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          inactivos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: adapter.from_api_to_grid(action.payload.grid_data.data),
            //data: action.payload.grid_data.data
          },
        },
      };
    },
    [get_stock_v1.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        stock: action.payload,
      };
    },
    [getById.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        data: adapter.from_api_to_form(action.payload.entity),
        data_not_adapted: action.payload.entity,
      };
    },
    [form.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [submit.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
    [submitStockV1.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
    [check_characteristic_values_select.fulfilled.type]: (state, action) => {
      return state;
      /*return {
                ...state,
                loading: false
            }*/
    },
  },
});

export const { clean, cleanMessage } = slice.actions;

export default slice.reducer;
