import axios from "axios";

class CharacteristicValue {
  constructor(base_url) {
    this.name = "/characteristic_values";
    this.url = base_url + this.name;
  }

  select = async (characteristic_type) => {
    return await axios.get(`${this.url}/select/${characteristic_type}`);
  };
}

export default CharacteristicValue;
