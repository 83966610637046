import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CharacteristicValue } from "../../services/Common";

const REDUCER_MODULE_NAME = "CHARACTERISTIC_VALUE";

export const initialState = {
  loading: false,
  select: [],
  error: "",
};

export const select = createAsyncThunk(
  `SELECT_${REDUCER_MODULE_NAME}`,
  async ({ base_url, characteristic_type }, { getState }) => {
    const { characteristic_values } = getState();

    const service = new CharacteristicValue(base_url);

    const characteristic_values_select = await service.select(
      characteristic_type
    );

    let new_select = [...characteristic_values.select];

    if (new_select.length === 1 && new_select[0].characteristic_type === "") {
      new_select = [];
    }

    const is_characteristic_type_loaded = new_select.find(
      (item) => item.characteristic_type === characteristic_type
    );

    if (!is_characteristic_type_loaded) {
      new_select.push({
        characteristic_type,
        values: characteristic_values_select.data.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      });
    }

    return {
      select: new_select,
    };
  }
);

//export const ready = createAsyncThunk(`READY_${REDUCER_MODULE_NAME}`);

const loadingReducer = (state, _) => {
  return {
    ...state,
    loading: true,
  };
};

export const characteristicValueSlice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [select.pending.type]: loadingReducer,
    //[ready.pending.type]: loadingReducer,

    [select.fulfilled.type]: (state, action) => {
      return {
        ...state,
        select: [...state.select, ...action.payload.select],
      };
    },
    /*[select.pending.type]: (state, _) => {
            return {
                ...state,
                loading: false
            }
        }*/
  },
});

export default characteristicValueSlice.reducer;
