import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: true,
  foldeable: false,
};

export const toggle = createAsyncThunk("toggle", (payload) => payload);

export const sidebarSlice = createSlice({
  name: "SIDEBAR",
  initialState,
  extraReducers: {
    [toggle.fulfilled.type]: (_, action) => {
      return {
        foldeable: action.payload.foldeable,
        visible: action.payload.visible,
      };
    },
  },
});

export default sidebarSlice.reducer;
