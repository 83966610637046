class Category {
  from_api_to_grid = (rows) => {
    return rows.map((data) => ({
      ...data,
      category_type: data.category_type?.value,
    }));
  };
}

export default new Category();
