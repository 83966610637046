import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Logs } from "../../services/Logs";

const service = new Logs();

const REDUCER_MODULE_NAME = "LOGS";

const initialState = {
  loading: false,
  data: [],
  message: "",
  error: "",
};

const loadingReducer = (state, _) => {
  return {
    ...state,
    loading: true,
  };
};

export const grid = createAsyncThunk(
  `GRID_${REDUCER_MODULE_NAME}`,
  async ({ company, branch, api_name }) => {
    const { data } = await service.grid(company, branch, api_name);

    return {
      data,
    };
  }
);

export const slice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [grid.pending.type]: loadingReducer,

    [grid.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        data: [...action.payload.data],
      };
    },
  },
});

export default slice.reducer;
