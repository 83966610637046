export default class Product {
  from_api_to_grid = (data) =>
    data.map((item) => ({
      ...item,
      description: item.description || "",
    }));

  from_form_to_api = ({ price, variants, original_price, ...data }) => ({
    ...data,
    characteristics: data.characteristics.filter(
      (characteristic) =>
        characteristic.characteristic_type_id && characteristic.value
    ),
    skus: data.skus
      .filter((sku) => sku.active)
      .map(({ active, ...sku }) => ({
        ...sku,
        characteristics: sku.characteristics.map((characteristic) => ({
          characteristic_type_id: characteristic.characteristic_type_id,
          value: characteristic.value_label,
        })),
        money: sku.money.map((item_money) => ({
          ...item_money,
          price: item_money.price ? parseFloat(item_money.price) : null,
          original_price: item_money.original_price
            ? parseFloat(item_money.original_price)
            : 0,
        })),
        stock_available: parseInt(sku.stock_available),
        stock_total: parseInt(sku.stock_total),
      })),
  });

  from_api_to_form = (data) => ({
    name: data.name,
    description: data.description,
    price:
      Math.min.apply(
        data.pms
          .map((sku) => sku?.money)
          .filter((money) => money?.[0]?.price)
          .map((money) => money?.[0]?.price)
      ) === "Infinity" || "",
    original_price:
      Math.min.apply(
        data.pms
          .map((sku) => sku?.money)
          .filter((money) => money?.[0]?.original_price)
          .map((money) => money?.[0]?.original_price)
      ) === "Infinity" || "",
    category_id: data.category.id,
    characteristics: data.product_variant_option.map((characteristic) => ({
      characteristic_type_id:
        characteristic.characteristic_value.characteristic_type.id,
      value: characteristic.characteristic_value.value,
    })),
    variants: data.pms
      .flatMap((sku) => sku?.sku_variant_option)
      .reduce((group, iteration) => {
        const findId = group.findIndex(
          (item) =>
            item.characteristic_type_id ===
            iteration.characteristic_value.characteristic_type.id
        );
        if (findId > -1) {
          group[findId] = {
            characteristic_type_id: group[findId].characteristic_type_id,
            value: [
              ...new Set([
                ...group[findId].value,
                iteration.characteristic_value.id,
              ]),
            ],
          };

          return group;
        } else {
          return [
            ...group,
            {
              characteristic_type_id:
                iteration.characteristic_value.characteristic_type.id,
              value: [iteration.characteristic_value.id],
            },
          ];
        }
      }, []),
    skus: data.pms.map((sku) => ({
      id: sku.id,
      code: sku.code,
      active: true,
      description: sku.description,
      characteristics: sku.sku_variant_option.map((characteristic) => ({
        characteristic_type_id:
          characteristic.characteristic_value.characteristic_type.id,
        value: characteristic.characteristic_value.id,
        value_label: characteristic.characteristic_value.value,
      })),
      money: sku.money?.map((item) => ({
        original_price: parseFloat(item.original_price),
        price: parseFloat(item.price) || "",
        percent: item.percent,
        tax: item.tax,
        currency_code: item.currency_code,
      })),
      stock_available: sku.stock_available,
      stock_total: sku.stock_total,
    })),
  });
}
