import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FiscalCondition } from "../../services/Person Management";

export const initialState = {
  loading: false,
  select: [],
  error: "",
};

const fiscalConditionService = new FiscalCondition();

export const select = createAsyncThunk("select_fiscal_conditions", async () => {
  const fiscal_conditions = (await fiscalConditionService.select()).data.map(
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  return {
    fiscal_conditions: [
      { value: "", label: "Seleccione una opción." },
      ...fiscal_conditions,
    ],
  };
});

export const fiscalConditionSlice = createSlice({
  name: "FISCAL_CONDITION",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(select.fulfilled.type, (state, action) => {
      return {
        ...state,
        select: action.payload.fiscal_conditions,
      };
    });
  },
});

export default fiscalConditionSlice.reducer;
