import axios from "axios";

export default class Logs {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_LOGS;
    this.name = "/logs";
    this.url = this.base_url + this.name;
  }

  grid = async (company = "a", branch = "a", api_name = "a") => {
    return await axios.get(
      `${this.url}?company=${company}&branch=${branch}&api_name=${api_name}`
    );
  };
}
