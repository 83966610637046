import axios from "axios";

export default class Person {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_PERSON;
    this.name = "/person";
    this.url = this.base_url + this.name;
  }

  grid = async (skip = 0, take = 100, active) => {
    return await axios.get(
      `${this.url}?skip=${skip}&take=${take}&active=${active}`
    );
  };

  getById = async (id) => {
    return await axios.get(`${this.url}/${id}`);
  };

  create = async (payload) => {
    await axios.post(this.url, payload);
  };

  update = async (id, payload) => {
    await axios.put(`${this.url}/${id}`, payload);
  };

  changeActive = async (id) => {
    await axios.patch(`${this.url}/${id}`);
  };
}
