import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CategoryType } from "../../services/Catalog Management";

const service = new CategoryType();

const REDUCER_MODULE_NAME = "CATEGORY_TYPE";

const initialState = {
  grid: {
    activos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
    inactivos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
  },
  loading: false,
  formIsReady: false,
  data: "",
  message: "",
  error: "",
};

const loadingReducer = (state, _) => {
  return {
    ...state,
    loading: true,
  };
};

export const select = createAsyncThunk(
  `SELECT_${REDUCER_MODULE_NAME}`,
  async () => {
    const { data } = await service.select();

    return {
      entities: [
        { value: "", label: "Seleccione una opción." },
        ...data.map((item) => ({
          value: item.id,
          label: item.value,
        })),
      ],
    };
  }
);

export const grid_activos = createAsyncThunk(
  `GRID_ACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, true);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const grid_inactivos = createAsyncThunk(
  `GRID_INACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, false);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const changeActive = createAsyncThunk(
  `CHANGE_ACTIVE_${REDUCER_MODULE_NAME}`,
  async ({ id, active }, { dispatch, getState }) => {
    await service.changeActive(id, { active: !active });

    dispatch(
      grid_activos({
        skip: initialState.grid.activos.skip,
        take: initialState.grid.activos.take,
      })
    );
    dispatch(
      grid_inactivos({
        skip: initialState.grid.inactivos.skip,
        take: initialState.grid.inactivos.take,
      })
    );
  }
);

export const form = createAsyncThunk(
  `FORM_${REDUCER_MODULE_NAME}`,
  async (id, { dispatch }) => {
    if (id) {
      dispatch(getById(id));
    }

    return {
      loading: false,
    };
  }
);

export const getById = createAsyncThunk(
  `GET_${REDUCER_MODULE_NAME}`,
  async (id) => {
    const entity = await service.getById(id);

    return {
      entity: entity.data,
    };
  }
);

export const submit = createAsyncThunk(
  `SUBIMIT_FORM_${REDUCER_MODULE_NAME}`,
  async ({ id, data }) => {
    try {
      if (id) {
        await service.update(id, data);
      } else {
        await service.create(data);
      }

      return {
        error: "",
        message: `Tipo de Categoría ${
          id ? "modificada" : "creada"
        } correctamente.`,
      };
    } catch (error) {
      return {
        error: error.message,
        message: "",
      };
    }
  }
);

export const slice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {
    clean() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [select.pending.type]: loadingReducer,
    [grid_activos.pending.type]: loadingReducer,
    [grid_inactivos.pending.type]: loadingReducer,
    [getById.pending.type]: loadingReducer,
    [form.pending.type]: loadingReducer,
    [submit.pending.type]: loadingReducer,

    [select.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        select: action.payload.entities,
      };
    },
    [grid_activos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          activos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            //data: personAdapter.from_api_to_grid(action.payload.grid_data.data)
            data: action.payload.grid_data.data,
          },
        },
      };
    },
    [grid_inactivos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          inactivos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            //data: personAdapter.from_api_to_grid(action.payload.grid_data.data)
            data: action.payload.grid_data.data,
          },
        },
      };
    },
    [getById.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.payload.entity,
      };
    },
    [form.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [submit.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
  },
});

export const { clean } = slice.actions;

export default slice.reducer;
