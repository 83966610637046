import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DocumentType } from "../../services/Person Management";

export const initialState = {
  loading: false,
  select: [],
  error: "",
};

const documentTypeService = new DocumentType();

export const select = createAsyncThunk("select_document_types", async () => {
  const document_types = (await documentTypeService.select()).data.map(
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  return {
    document_types: [
      { value: "", label: "Seleccione una opción." },
      ...document_types,
    ],
  };
});

export const documentTypeSlice = createSlice({
  name: "DOCUMENT_TYPE",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(select.fulfilled.type, (state, action) => {
      return {
        ...state,
        select: action.payload.document_types,
      };
    });
  },
});

export default documentTypeSlice.reducer;
